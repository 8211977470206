import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import {
  OnboardingProvideObject,
  OnboardingContainerTypeEnum,
  OnboardingRouteItemNameEnum,
} from "@/types/onboarding";
import { BridgeStatusEnum } from "@/types/webview";
import { handleEnableNotifications } from "@/utils/api";
import { onboardingInjectionKey } from "@/utils/onboarding/onboardingInjectionKey";
import { computed, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { OnboardingContainerPropsObject } from "@/components/onboarding/OnboardingContainer";
import { handleOnboardingContinue } from "@/utils/settings";
import { getOnboardingNotificationTranslationString } from "./getOnboardingNotificationTranslationString";

export const useGetOnboardingNotificationsProperties = () => {
  const injected = inject<OnboardingProvideObject>(onboardingInjectionKey, {
    type: OnboardingContainerTypeEnum.Settings,
    routeName: OnboardingRouteItemNameEnum.Notifications,
  });
  const router = useRouter();

  const status = useComputedValue<BridgeStatusEnum>(
    GetterTypes.GET_NOTIFICATIONS_STATUS,
  );
  const notificationOnboardingProperties =
    computed<OnboardingContainerPropsObject>(() => {
      const statusValue = status.value;
      return {
        title: getOnboardingNotificationTranslationString({
          status: statusValue,
          property: "title",
        }),
        text: getOnboardingNotificationTranslationString({
          status: statusValue,
          property: "text",
        }),
        primaryButton: primaryButton.value,
        secondaryButton: secondaryButton.value,
        imageName: "notifications.jpg",
        componentName: "notifications",
      };
    });
  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handlePrimaryButtonClick,
      text: getOnboardingNotificationTranslationString({
        status: status.value,
        property: "primaryButtonText",
      }),
    };
  });
  const secondaryButton = computed<ButtonObject | undefined>(() => {
    return {
      handleClick: handleRedirect,
      text: getOnboardingNotificationTranslationString({
        status: status.value,
        property: "secondaryButtonText",
      }),
    };
  });

  watch(status, () => {
    if (injected.type === OnboardingContainerTypeEnum.Settings) {
      return;
    }

    handleContinueOnboarding();
  });

  const handlePrimaryButtonClick = (): void => {
    switch (injected.type) {
      case OnboardingContainerTypeEnum.Onboarding:
        handleEnableNotifications();
        return;

      default:
        break;
    }

    switch (status.value) {
      case BridgeStatusEnum.Unused:
        handleEnableNotifications();
        break;

      case BridgeStatusEnum.Enabled:
      case BridgeStatusEnum.Disabled:
        handleRedirectBack();
        break;

      default:
        break;
    }
  };
  const handleRedirectBack = (): void => {
    router.back();
  };
  const handleContinueOnboarding = (): void => {
    if (!injected.routeName) {
      return;
    }
    handleOnboardingContinue(injected.routeName);
  };
  const handleRedirect = (): void => {
    switch (injected.type) {
      case OnboardingContainerTypeEnum.Onboarding:
        handleContinueOnboarding();
        break;
      case OnboardingContainerTypeEnum.Settings:
        handleRedirectBack();
        break;

      default:
        break;
    }
  };

  return { notificationOnboardingProperties };
};
