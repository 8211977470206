import store, { MutationTypes } from "@/store";
import { PopupTypes, LayoutBackgroundColorTypes } from "@/store/app/state";
import { PhoneContact } from "@/store/users/state";
import {
  BridgeActionTypeEnum,
  BridgeMessageAppDetailsResponseObject,
  BridgeMessageResponseObject,
  BridgeStatusEnum,
} from "@/types/webview";
import { getNewestAppVersionString, getVersionNumber } from "../app";
import { handleAllowAccessToContacts } from "./handleAllowAccessToContacts";
import { getIsJSON } from "./getIsJSON";

export const handleReceiveContainerMessage = async (e: MessageEvent) => {
  // guard to let only messages from webview container go through
  if (e.origin !== window.location.origin || !e.source || !e.data) {
    return;
  }

  const isParsableJson =
    typeof e.data !== "string" || e.data === "undefined" || !getIsJSON(e.data);

  if (isParsableJson) {
    return;
  }

  const message: BridgeMessageResponseObject = JSON.parse(e.data);

  switch (message.type) {
    case BridgeActionTypeEnum.Contacts:
      {
        const contactsData: PhoneContact[] = JSON.parse(message.data);
        store.commit(MutationTypes.SET_PHONE_CONTACTS, contactsData);
        const status = contactsData.length
          ? BridgeStatusEnum.Enabled
          : BridgeStatusEnum.Disabled;
        store.commit(MutationTypes.SET_PHONE_CONTACTS_STATUS, status);
      }
      break;

    case BridgeActionTypeEnum.NotificationsStatus:
      {
        store.commit(
          MutationTypes.SET_NOTIFICATIONS_STATUS,
          message.data as BridgeStatusEnum,
        );
      }
      break;
    case BridgeActionTypeEnum.ContactsStatus:
      {
        const status: BridgeStatusEnum = message.data as BridgeStatusEnum;
        store.commit(MutationTypes.SET_PHONE_CONTACTS_STATUS, status);

        if (status !== BridgeStatusEnum.Enabled) {
          return;
        }
        handleAllowAccessToContacts();
      }
      break;
    case BridgeActionTypeEnum.AppDetails:
      {
        const appDetailsResponseObject: BridgeMessageAppDetailsResponseObject =
          JSON.parse(message.data);

        const newestVersion = getNewestAppVersionString();

        const { lastSeenAppVersion, currentAppVersion } =
          appDetailsResponseObject;

        const transformedCurrentAppVersion = currentAppVersion.split(" ")[0];
        store.commit(MutationTypes.SET_APP_DETAILS, appDetailsResponseObject);

        if (
          transformedCurrentAppVersion === lastSeenAppVersion &&
          lastSeenAppVersion === newestVersion
        ) {
          return;
        }

        if (
          newestVersion === transformedCurrentAppVersion &&
          getVersionNumber(newestVersion) > getVersionNumber(lastSeenAppVersion)
        ) {
          // @ts-expect-error
          store.commit(MutationTypes.SHOW_POPUP, {
            popupType: PopupTypes.WHAT_IS_NEW,
            processBackgroundColorObject: {
              top: LayoutBackgroundColorTypes.DARKER_GRAY,
              bottom: LayoutBackgroundColorTypes.WHITE,
            },
          });
        }
      }
      break;
    case BridgeActionTypeEnum.MicrophoneStatus:
      {
        store.commit(
          MutationTypes.SET_MICROPHONE_STATUS,
          message.data as BridgeStatusEnum,
        );
      }
      break;
    case BridgeActionTypeEnum.ShareAndroid:
      {
        store.commit(MutationTypes.SET_SHARED_MESSAGE, {
          sharedMessage: message.data,
        });
      }
      break;
    default:
      break;
  }
};
