<template>
  <div
    ref="root"
    class="chat-wrapper"
    :class="{ isBlocking: isBlockingTextShown }"
    :style="style"
  >
    <ChatTopBar />
    <ChatContent :messages="messages" />
    <ChatInputBar v-if="!isBlockingTextShown" :members="members" />
  </div>
</template>

<script setup lang="ts">
import ChatTopBar from "@/components/chat/chatContainer/ChatTopBar/ChatTopBar.vue";
import ChatInputBar from "@/components/chat/chatContainer/ChatInputBar/ChatInputBar.vue";
import ChatContent from "@/components/chat/chatContainer/ChatContent/ChatContent.vue";
import { useGetChatContentContainerProperties } from "./useGetChatContentContainerProperties";
import { ref } from "vue";
import { Message, NonAutomaticMessage, TextMessage } from "@/store/chats/types";
import { messageInjectionKey } from "@/utils/chat";
import { provide } from "vue";
import {
  getIsAutomaticMessageType,
  getIsMessageTextType,
} from "@/utils/message";
import { useStore } from "vuex";
import { ActionTypes } from "@/store";

const { dispatch } = useStore();
const replyMessage = ref<NonAutomaticMessage | null>(null);
const handleReplyToMessage = (message: Message) => {
  if (getIsAutomaticMessageType(message)) {
    return;
  }
  replyMessage.value = message;
  modifiedMessage.value = null;
};
const modifiedMessage = ref<TextMessage | null>(null);
const handleModifyMessage = (message: Message) => {
  if (!getIsMessageTextType(message)) {
    return;
  }
  modifiedMessage.value = message;
  replyMessage.value = null;
};

const handleRemoveMessage = (message: Message) => {
  dispatch(ActionTypes.DELETE_MESSAGES, { messageIds: [message.id] });
};

const clearInjectedMessage = () => {
  replyMessage.value = null;
  modifiedMessage.value = null;
};

provide(messageInjectionKey, {
  replyMessage,
  handleReplyToMessage,
  clearInjectedMessage,
  modifiedMessage,
  handleModifyMessage,
  handleRemoveMessage,
});

const { root, style, isBlockingTextShown, messages, members } =
  useGetChatContentContainerProperties();
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
