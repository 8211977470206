<template>
  <PopupInfo
    title="popup.PopupInfoUnblockUser.title"
    label="popup.PopupInfoUnblockUser.label"
    btn-text="popup.PopupInfoUnblockUser.btnText"
    :action="clickAction"
  />
</template>

<script>
import { ActionTypes, store, GetterTypes, MutationTypes } from "@/store";
import { getPartnerIdFromChatId } from "@/firebase/utils";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      authId: GetterTypes.GET_AUTH_ID,
      selectedChatId: GetterTypes.GET_SELECTED_CHAT_ID,
    }),
  },
  methods: {
    async clickAction() {
      const chatId = this.selectedChatId ?? this.$route.params.chat_id;
      const partnerId = getPartnerIdFromChatId(this.authId, chatId);
      await store.dispatch(ActionTypes.UNBLOCK_USER, { partnerId, chatId });
      store.commit(MutationTypes.HIDE_POPUP);
    },
  },
};
</script>
