<template>
  <a
    v-if="isShown && isIos"
    :href="getLink()"
    target="_blank"
    rel="noopener noreferer"
    class="banner"
  >
    <img class="img" :src="bannerSrc" alt="mobile_installation_banner" />
  </a>
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { useComputedValue } from "@/composables";
import { getLanguage } from "@/utils/api";
import { AppEnvironment, LayoutTypes } from "@/store/app/state";
import { computed } from "vue";

const initialLoad = useComputedValue(GetterTypes.GET_CHATS_INITIAL_LOAD);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const language = getLanguage();
const environment = useComputedValue<AppEnvironment>(
  GetterTypes.GET_APP_ENVIRONMENT,
);
const isIos = computed(() => environment.value.isIos);
const isShown = computed(
  () =>
    initialLoad &&
    !environment.value.isMobileApp &&
    layoutType.value === LayoutTypes.MOBILE,
);

const googlePlayStore = process.env.VUE_APP_ANDROID_INSTALL_LINK;
const appleStore = process.env.VUE_APP_IOS_INSTALL_LINK;

const getLink = () => {
  return isIos.value ? appleStore : googlePlayStore;
};

const pathPrefix = "/banners/banner";
const os = isIos.value ? "appstore" : "playstore";
const bannerSrc = `${pathPrefix}-${os}-${language}.jpg`;
</script>

<style lang="scss">
@import "./MobileInstallationBanner.module.scss";
</style>
