import { Ref } from "vue";
import { getInjectionKey } from "../app";
import { Message, NonAutomaticMessage, TextMessage } from "@/store/chats/types";

interface MessageInjectionKeyReturnType {
  replyMessage: Ref<NonAutomaticMessage | null>;
  handleReplyToMessage: (message: Message) => void;
  clearInjectedMessage: () => void;
  modifiedMessage: Ref<TextMessage | null>;
  handleModifyMessage: (message: Message) => void;
  handleRemoveMessage: (message: Message) => void;
}

export const messageInjectionKey =
  getInjectionKey<MessageInjectionKeyReturnType>();
