<template>
  <div
    class="chat-top-bar"
    :class="{
      webview: isMobileApp,
    }"
  >
    <div class="chat-info">
      <MobileNavigationBackButton />
      <div class="chat-select">
        <span class="label">
          {{ $t("chat.chatContainer.ChatTopBarNewChat.label") }}
        </span>
        <div class="members">
          <div
            v-for="member in members"
            :key="member.id"
            class="member"
            @click="removeMember(member)"
          >
            {{ member.name }}
          </div>
        </div>
        <UsersSelectBox @set-selected-users="addMember" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MobileNavigationBackButton from "@/components/buttons/MobileNavigationBackButton.vue";
import UsersSelectBox from "@/components/select/UsersSelectBox.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { AppEnvironment } from "@/store/app/state";
import { Profile } from "@/store/auth/state";
import { computed } from "vue";

interface ChatTopBarNewChatProps {
  members: Profile[];
}

interface ChatTopBarNewChatEmits {
  (e: "remove-member", member: Profile): void;
  (e: "add-member", member: Profile): void;
}

withDefaults(defineProps<ChatTopBarNewChatProps>(), {
  members: () => [],
});

const emit = defineEmits<ChatTopBarNewChatEmits>();
const environment = useComputedValue<AppEnvironment>(
  GetterTypes.GET_APP_ENVIRONMENT,
);
const isMobileApp = computed(() => environment.value.isMobileApp);

const removeMember = (member: Profile) => {
  emit("remove-member", member);
};
const addMember = (member: Profile) => {
  emit("add-member", member);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatTopBar.scss";
</style>
