import store from "@/store";
import { ChatsGetterTypes } from "@/store/chats";
import { getIsRawMessageTextType } from "../message";

export const getIsChatEmptyFromId = (chatId: string) => {
  const rawChat = store.getters[ChatsGetterTypes.GET_RAW_CHAT](chatId);
  const lastMessage = rawChat.lastMessage;
  if (!lastMessage) {
    return true;
  }

  const isEmpty =
    getIsRawMessageTextType(lastMessage) &&
    !lastMessage.messageText &&
    !lastMessage.attachments?.length &&
    !lastMessage.isDeleted;

  if (isEmpty) {
    return true;
  }

  return !!lastMessage?.hide;
};
