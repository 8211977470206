<template>
  <ChatMessageContent
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  >
    <div class="friend-request-message" :class="{ isRequestForResponding }">
      <div class="content">
        <div class="top">
          <h3 v-if="isRequestForResponding" class="title">
            {{
              $t(
                "chat.chatContainer.messages.friendRequest.invitationFromUser",
                {
                  userName: message.senderName,
                },
              )
            }}
          </h3>
          <p class="text">
            {{ appliedText }}
          </p>
        </div>
        <div v-if="isRequestForResponding" class="bottom">
          <GenericButton
            text="chat.chatContainer.messages.friendRequest.declineButtonText"
            :variant="GenericButtonVariantEnum.Secondary"
            @click="handleDecline"
          />
          <GenericButton
            text="chat.chatContainer.messages.friendRequest.acceptButtonText"
            @click="handleAccept"
          />
        </div>
      </div>
    </div>
  </ChatMessageContent>
</template>

<script setup lang="ts">
import GenericButton from "@/components/buttons/GenericButton.vue";
import ChatMessageContent from "@/components/chat/chatContainer/ChatMessageContent/ChatMessageContent.vue";
import { ActionTypes } from "@/store";
import {
  FriendRequestMessage,
  FriendRequestStatus,
  Message,
} from "@/store/chats/types";
import { GenericButtonVariantEnum } from "@/types/button";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface ChatMessageProps {
  message: FriendRequestMessage;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageProps>();
const { t } = useI18n();
const { dispatch } = useStore();

const handleUpdateFriendRequest = async (status: FriendRequestStatus) => {
  await dispatch(ActionTypes.UPDATE_FRIEND_REQUEST, {
    userId: props.message.senderId,
    status,
  });
};

// TODO: add button handlers
const handleDecline = async () => {
  await handleUpdateFriendRequest(FriendRequestStatus.Cancelled);
};

const handleAccept = async () => {
  await handleUpdateFriendRequest(FriendRequestStatus.Accepted);
};

const isRequestForResponding = computed(() => {
  return (
    props.message.status === FriendRequestStatus.Pending &&
    !props.message.isMine
  );
});

const appliedText = computed(() => {
  if (
    props.message.isMine &&
    props.message.status !== FriendRequestStatus.Accepted
  ) {
    return t("chat.chatContainer.messages.friendRequest.requestSent");
  }
  if (props.message.isMine) {
    return t("chat.chatContainer.messages.friendRequest.accepted");
  }

  if (props.message.status === FriendRequestStatus.Pending) {
    return t("chat.chatContainer.messages.friendRequest.acceptInvitation");
  }

  if (props.message.status === FriendRequestStatus.Accepted) {
    return t("chat.chatContainer.messages.friendRequest.accepted");
  }

  return t("chat.chatContainer.messages.friendRequest.declined");
});
</script>

<style lang="scss" scoped>
@import "./ChatFriendRequestMessage.scss";
</style>
