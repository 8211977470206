import {
  useComputedValue,
  useGetMessageHandlers,
  useInfiniteScroll,
} from "@/composables";
import { getRestrictedMessagesRef } from "@/firebase/ref";
import { ActionTypes, GetterTypes, MutationTypes } from "@/store";
import { ChatBlockingStatusEnum, Message } from "@/store/chats/types";
import {
  showUnsentAttachmentsSnackbar,
  updateBottomBgColor,
} from "@/utils/chat";
import { watch, onMounted, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useGetChatContentValues } from "./useGetChatContentValues";
import { markMessageAsRead } from "@/services";
import _ from "lodash";
import { AppEnvironment } from "@/store/app/state";

interface ChatContentProps {
  messages: Message[];
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted?: boolean;
  userName?: string;
}

export const useGetChatContentProperties = (props: ChatContentProps) => {
  const {
    visibleMessages,
    isBlockingTextShown,
    authId,
    deletingMessages,
    convertedDateString,
    blockingStrings,
    el,
    bottomAnchorRef,
    chatId,
    firstMessage,
    lastMessageId,
    lastMessage,
    isSomeAttachmentUploading,
    initialLoad,
    height,
    lastConvertedMessage,
    chatDetail,
  } = useGetChatContentValues(props);
  const messageReactionId = useComputedValue<string | null>(
    GetterTypes.GET_MESSAGE_REACTION_ID,
  );
  const isReacting = computed(() => !!messageReactionId.value);
  const { commit, dispatch } = useStore();
  const environment = useComputedValue<AppEnvironment>(
    GetterTypes.GET_APP_ENVIRONMENT,
  );
  const isAlignedToTop = computed(() => {
    const alignToBottom =
      height.value > 0 && height.value < 450 && environment.value.isMobileApp;

    return !alignToBottom;
  });
  const { replyMessage, modifiedMessage } = useGetMessageHandlers();
  const isReplyingOrEditing = computed(
    () => !!replyMessage.value || !!modifiedMessage.value,
  );

  watch(isReacting, (newValue, oldValue) => {
    if (!newValue || newValue === oldValue) {
      return;
    }
    if (!(document.activeElement instanceof HTMLElement)) {
      return;
    }
    document.activeElement.blur();
  });
  watch(height, () => {
    if (!height.value) {
      return;
    }
    scrollToBottom();
  });
  watch(lastMessageId, () => {
    if (!lastMessageId.value) {
      return;
    }

    handleMarkMessageAsRead();
    scrollToBottom();
  });
  watch(isBlockingTextShown, () => {
    handleUpdateBottomBgColor();
  });
  watch(initialLoad, () => {
    if (!initialLoad.value) {
      return;
    }
    performInitialization();
  });
  watch(isSomeAttachmentUploading, () => {
    if (!isSomeAttachmentUploading.value) {
      return;
    }

    scrollToBottom();
  });

  onMounted(() => {
    performInitialization();
    dispatch(ActionTypes.ADD_MESSAGES_LISTENER, { chatId: chatId.value });
  });

  const performInitialization = () => {
    handleMarkMessageAsRead();
    scrollToBottom();
    showUnsentAttachmentsSnackbar({
      lastMessage: lastConvertedMessage.value,
      chatId: chatId.value,
      messages: props.messages,
    });
    handleUpdateBottomBgColor();
  };

  const handleLoadMoreMessages = async () => {
    if (!chatDetail.value) {
      return;
    }
    const chatId = chatDetail.value.id;
    if (!firstMessage.value || !data.canLoadMore) {
      return;
    }
    try {
      const messageSentAt = firstMessage.value.sentAt;
      const restrictedMessageRef = getRestrictedMessagesRef(
        chatId,
        messageSentAt,
      );
      const messagesRef = await restrictedMessageRef.limitToLast(20).get();
      const messages = messagesRef.docs.map((mess) => mess.data());

      if (messages.length < 20) {
        data.canLoadMore = false;
      }

      if (messages.length === 0) {
        return;
      }
      commit(MutationTypes.PREPEND_MESSAGES, { messages, chatId });
    } catch (error) {
      data.canLoadMore = false;
    }
  };
  const { scrollContainerRef, sentinelRef, data } = useInfiniteScroll(
    handleLoadMoreMessages,
  );

  const handleBlurInput = () => {
    const messageInput = el?.value?.querySelector("textarea");
    if (!messageInput) {
      return;
    }
    messageInput.blur();
  };
  const handleScroll = () => {
    if (!bottomAnchorRef.value) {
      return;
    }

    bottomAnchorRef.value.scrollIntoView(isAlignedToTop.value);
  };
  const scrollToBottom = _.debounce(handleScroll, 1000);

  onUnmounted(() => {
    scrollToBottom.cancel();
  });

  const handleUpdateBottomBgColor = () => {
    updateBottomBgColor(isBlockingTextShown.value);
  };

  const handleMarkMessageAsRead = () => {
    markMessageAsRead({ lastMessage: lastMessage.value, chatId: chatId.value });
  };

  return {
    handleBlurInput,
    visibleMessages,
    isBlockingTextShown,
    authId,
    deletingMessages,
    convertedDateString,
    scrollToBottom,
    blockingStrings,
    el,
    scrollContainerRef,
    sentinelRef,
    bottomAnchorRef,
    isScrollDisabled: isReacting,
    isReplyingOrEditing,
  };
};
