import { GetterTypes, MutationTypes } from "@/store";
import {
  LayoutTypes,
  LayoutBackgroundColorTypes,
  LayoutType,
  PopupType,
  AppEnvironment,
} from "@/store/app/state";
import { ChatBlockingStatusEnum } from "@/store/chats/types";
import { handleSetIosContainerSafeAreaBg } from "@/utils/api";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useComputedValue } from "./useComputedValue";
import { getIsBlockingTextShown } from "@/utils/chat";

export const useHandleBackgroundChange = () => {
  const route = useRoute();
  const { commit } = useStore();
  const backgroundColorTypeTop = useComputedValue<LayoutBackgroundColorTypes>(
    GetterTypes.GET_LAYOUT_BG_COLOR_TOP,
  );
  const backgroundColorTypeBottom =
    useComputedValue<LayoutBackgroundColorTypes>(
      GetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM,
    );
  const layoutType = useComputedValue<LayoutType>(GetterTypes.GET_LAYOUT_TYPE);
  const blockingStatus = useComputedValue<ChatBlockingStatusEnum>(
    GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
  );
  const popupType = useComputedValue<PopupType>(GetterTypes.GET_POPUP_TYPE);
  const environment = useComputedValue<AppEnvironment>(
    GetterTypes.GET_APP_ENVIRONMENT,
  );

  watch([route, blockingStatus, popupType], () => {
    setBgColor();
  });
  watch([backgroundColorTypeTop, backgroundColorTypeBottom], () => {
    setIosContainerSafeAreaBg();
  });

  const getThisRoute = () => {
    const thisRoute = route.matched.find((r) => r.name === route.name);
    return thisRoute;
  };

  const setBgColor = () => {
    if (popupType.value) {
      return;
    }

    const thisRoute = getThisRoute();
    if (!thisRoute || layoutType.value !== LayoutTypes.MOBILE) {
      return;
    }

    // @ts-expect-error
    const { topBackgroundColorType, bottomBackgroundColorType } =
      thisRoute.props.default;

    let appliedBottomBackgroundColorType = bottomBackgroundColorType;
    if (thisRoute.path === "/:chat_id" && getIsBlockingTextShown()) {
      appliedBottomBackgroundColorType = LayoutBackgroundColorTypes.ORANGE;
    }
    commit(
      MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_TOP,
      topBackgroundColorType,
    );
    commit(
      MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_BOTTOM,
      appliedBottomBackgroundColorType,
    );
    setIosContainerSafeAreaBg();
  };

  const setIosContainerSafeAreaBg = () => {
    if (!environment.value.isIos) {
      return;
    }
    handleSetIosContainerSafeAreaBg({
      topColor: backgroundColorTypeTop.value,
      bottomColor: backgroundColorTypeBottom.value,
    });
  };

  onMounted(() => {
    setBgColor();
  });
};
