<template>
  <span class="deleted-message-text" :class="{ isMine }">{{ text }}</span>
</template>

<script setup lang="ts">
import { MessageType } from "@/store/chats/types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
  isMine: boolean;
  messageType?: MessageType;
}
const props = defineProps<Props>();
const { t } = useI18n();

const text = computed(() => {
  if (props.messageType === MessageType.Audio) {
    return t("chat.chatContainer.messages.deleting.deletedAudioMessage");
  }
  return t("chat.chatContainer.messages.deleting.deletedMessage");
});
</script>

<style lang="scss" scoped>
@import "./DeletedMessage.scss";
</style>
