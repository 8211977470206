import { LocaleTypeEnum } from "@/types/localization";
import { OnboardingRouteItem } from "@/types/onboarding";
import {
  BridgeMessageAppDetailsResponseObject,
  BridgeStatusEnum,
} from "@/types/webview";
import { Profile } from "../auth/state";
import {
  getIsAppStandalone,
  isAppEnvironmentAndroid,
  isAppEnvironmentIos,
} from "@/utils/app";

export interface Popup {
  type: PopupType;
  data: any;
  backgroundColor: PopupBackgroundColorObject;
}

export type PopupType =
  | PopupTypes.EDIT_GROUP
  | PopupTypes.EDIT_GROUP_MEMBERS
  | PopupTypes.INFO_DELETE_CHAT
  | PopupTypes.INFO_DELETE_MESSAGES
  | PopupTypes.INFO_LEAVE_GROUP
  | PopupTypes.INFO_MUTE_CHAT
  | PopupTypes.NOTIFICATIONS_SETTING
  | PopupTypes.INFO_DELETE_USER_ACCOUNT
  | null;

export enum PopupTypes {
  EDIT_CHAT_APPEARANCE = "EDIT_CHAT_APPEARANCE",
  EDIT_GROUP = "EDIT_GROUP",
  EDIT_GROUP_MEMBERS = "EDIT_GROUP_MEMBERS",
  INFO_DELETE_CHAT = "INFO_DELETE_CHAT",
  INFO_DELETE_MESSAGES = "INFO_DELETE_MESSAGES",
  INFO_LEAVE_GROUP = "INFO_LEAVE_GROUP",
  INFO_MUTE_CHAT = "INFO_MUTE_CHAT",
  NOTIFICATIONS_SETTING = "NOTIFICATIONS_SETTING",
  INFO_DELETE_USER_ACCOUNT = "INFO_DELETE_USER_ACCOUNT",
  INFO_REPORT_USER = "INFO_REPORT_USER",
  INFO_BLOCK_USER = "INFO_BLOCK_USER",
  INFO_UNBLOCK_USER = "INFO_UNBLOCK_USER",
  WHAT_IS_NEW = "WHAT_IS_NEW",
}

export enum CurrentChatTypes {
  ONE_TO_ONE_CHAT = "ONE_TO_ONE_CHAT",
  GROUP_CHAT = "GROUP_CHAT",
  NEW_CHAT = "NEW_CHAT",
  NO_CHAT = "NO_CHAT",
}

export interface NewChat {
  type: NewChatType;
  members: Profile[];
  chatId: string | null;
}

export enum LayoutTypes {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export type LayoutType = LayoutTypes.DESKTOP | LayoutTypes.MOBILE;

export interface Layout {
  type: LayoutType;
  width: number;
  height: number;
  backgroundColorType: BackgroundTypeObject;
  messageInputHeight: number;
}

export type NewChatType =
  | NewChatTypes.ONE_TO_ONE_CHAT
  | NewChatTypes.GROUP_CHAT
  | null;

export enum NewChatTypes {
  ONE_TO_ONE_CHAT = "ONE_TO_ONE_CHAT",
  GROUP_CHAT = "GROUP_CHAT",
}

export enum LayoutBackgroundColorTypes {
  GRAY = "GRAY",
  WHITE = "WHITE",
  ORANGE = "ORANGE",
  ORANGE_OVERLAY = "ORANGE_OVERLAY",
  DARK_GRAY = "DARK_GRAY",
  BLACK = "BLACK",
  BLACK_NON_TRANSPARENT = "BLACK_NON_TRANSPARENT",
  GRAY_OVERLAY = "GRAY_OVERLAY",
  DARKER_GRAY = "DARKER_GRAY",
}

export type FullSizedImage = {
  src?: string;
};

export interface SettingsObject {
  status: BridgeStatusEnum;
}

export interface Settings {
  notifications: SettingsObject;
  contacts: SettingsObject;
  microphone: SettingsObject;
}

export enum AppEnvironmentEnum {
  Web = "web",
  IOS = "ios",
  Android = "android",
}

export interface Snackbar {
  isMounted: boolean;
  isShown: boolean;
  title: string;
  text: string;
  timeoutSeconds: number;
  bottom: number;
  type: SnackbarType;
}

export enum SnackbarType {
  Failure = "failure",
  Success = "success",
}

export interface SnackbarRequestObject {
  title: string;
  text?: string;
  timeoutSeconds?: number;
  bottom?: number;
  type?: SnackbarType;
}

export interface ErrorHandlerSnackbarRequestObject {
  [key: string]: SnackbarRequestObject;
}

export interface AppEnvironment {
  operatingSystem: AppEnvironmentEnum;
  isIos: boolean;
  isAndroid: boolean;
  isMobileApp: boolean;
}

export interface AppState {
  popup: Popup;
  layout: Layout;
  fullSizeImage: FullSizedImage;
  lang: LocaleTypeEnum;
  settings: Settings;
  snackbar: Snackbar;
  isLoading: boolean;
  onboardingRoutes: readonly OnboardingRouteItem[];
  appDetails: BridgeMessageAppDetailsResponseObject;
  environment: AppEnvironment;
}

export interface BackgroundTypeObject {
  top: LayoutBackgroundColorTypes;
  bottom: LayoutBackgroundColorTypes;
}

export interface PopupBackgroundColorObject {
  failure: BackgroundTypeObject;
  success: BackgroundTypeObject;
  process: BackgroundTypeObject;
}

export const defaultSnackbar: Snackbar = {
  isMounted: false,
  isShown: false,
  title: "",
  text: "",
  timeoutSeconds: 3,
  bottom: 35,
  type: SnackbarType.Failure,
};

export const defaultBackgroundColorObject: BackgroundTypeObject = {
  top: LayoutBackgroundColorTypes.WHITE,
  bottom: LayoutBackgroundColorTypes.WHITE,
};
export const defaultProcessBackgroundColorObject: BackgroundTypeObject = {
  top: LayoutBackgroundColorTypes.DARK_GRAY,
  bottom: LayoutBackgroundColorTypes.DARK_GRAY,
};
const getDefaultOs = () => {
  if (isAppEnvironmentAndroid) {
    return AppEnvironmentEnum.Android;
  }
  if (isAppEnvironmentIos) {
    return AppEnvironmentEnum.IOS;
  }
  return AppEnvironmentEnum.Web;
};
const isIos = isAppEnvironmentIos && getIsAppStandalone();
const isAndroid = isAppEnvironmentAndroid && getIsAppStandalone();

export const appState: AppState = {
  popup: {
    type: null,
    data: {},
    backgroundColor: {
      failure: defaultBackgroundColorObject,
      success: defaultBackgroundColorObject,
      process: defaultProcessBackgroundColorObject,
    },
  },
  layout: {
    type: LayoutTypes.DESKTOP,
    width: 0,
    backgroundColorType: defaultBackgroundColorObject,
    messageInputHeight: 0,
    height: window.innerHeight,
  },
  fullSizeImage: {
    src: undefined,
  },
  lang: LocaleTypeEnum.CS,
  settings: {
    notifications: {
      status: BridgeStatusEnum.Unused,
    },
    contacts: {
      status: BridgeStatusEnum.Unused,
    },
    microphone: {
      status: BridgeStatusEnum.Unused,
    },
  },
  snackbar: defaultSnackbar,
  isLoading: false,
  onboardingRoutes: [],
  appDetails: {
    lastSeenAppVersion: "",
    currentAppVersion: "",
    deviceModelName: "",
    osVersion: "",
    regionCode: "",
  },
  environment: {
    operatingSystem: getDefaultOs(),
    isIos,
    isAndroid,
    isMobileApp: isIos || isAndroid,
  },
};
