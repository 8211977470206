<template>
  <AudioPlayer v-bind="audioPlayerProps" />
</template>

<script setup lang="ts">
import { AudioMessage } from "@/store/chats/types";
import AudioPlayer from "../AudioPlayer/AudioPlayer.vue";
import { useAudioPlayer } from "../AudioPlayer";
import { computed } from "vue";

interface ChatMessageProps {
  message: AudioMessage;
}

const props = defineProps<ChatMessageProps>();
const audioAttachment = computed(() => props.message.attachments?.[0]);

const { audioPlayerProps } = useAudioPlayer({
  isRecorder: false,
  isMine: props.message.isMine,
  containerId: `player_${props.message.id}`,
  url: audioAttachment.value?.url,
  duration: audioAttachment.value?.duration,
});
</script>
