<template>
  <div
    v-if="isMobileApp && isMounted"
    class="snackbar-container"
    :style="{ bottom: snackbarBottomString }"
  >
    <div class="content" :class="snackbar.type.toLowerCase()">
      <div class="left">
        <p class="title">{{ $t(snackbar.title) }}</p>
        <p v-if="snackbar.text" class="text">{{ $t(snackbar.text) }}</p>
      </div>
      <Icon
        :icon="IconType.CROSS_WHITE"
        class="closeIcon"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
import { ActionTypes, GetterTypes, store } from "@/store";

import { mapGetters } from "vuex";
import { IconType } from "@/types/icons";
import Icon from "../icons/Icon/Icon.vue";

export default {
  components: { Icon },
  data() {
    return { timeout: null, IconType };
  },
  computed: {
    ...mapGetters({
      snackbar: GetterTypes.GET_SNACKBAR,
      isShown: GetterTypes.GET_SNACKBAR_IS_SHOWN,
      isMounted: GetterTypes.GET_SNACKBAR_IS_MOUNTED,
      isMobileApp: GetterTypes.GET_APP_ENVIRONMENT.isMobileApp,
    }),
    snackbarBottomString() {
      let bottomNumber = -100;
      if (this.isShown) {
        bottomNumber = this.snackbar.bottom;
      }
      return `${bottomNumber}px`;
    },
  },
  watch: {
    isShown() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.isShown) {
          store.dispatch(ActionTypes.UNMOUNT_SNACKBAR);
        }
      }, this.snackbar.timeoutSeconds * 1000);
    },
  },
  methods: {
    handleClose() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      store.dispatch(ActionTypes.UNMOUNT_SNACKBAR);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/snackbar/snackbar.scss";
</style>
