<template>
  <div class="message-emoji-reactions-emojis-panel" :class="{ isMine }">
    <MessageEmojiReactionsPanelItem
      v-for="(emoji, i) in REACTIONS_EMOJI_CHARACTERS"
      :key="i"
      :emoji="emoji"
      @insert-emoji="addReaction"
    />
    <div class="emoji-thumb" />
  </div>
</template>

<script setup lang="ts">
import { Chat } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { ActionTypes, GetterTypes } from "@/store";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { REACTIONS_EMOJI_CHARACTERS } from "@/data/emoji/emojiCharacters";
import MessageEmojiReactionsPanelItem from "../MessageEmojiReactionsPanelItem/MessageEmojiReactionsPanelItem.vue";

interface MessageEmojiReactionsPanelProps {
  messageId: string;
  isMine: boolean;
}

interface Emits {
  (e: "closePanel"): void;
}

const emit = defineEmits<Emits>();

const { dispatch } = useStore();
const props = defineProps<MessageEmojiReactionsPanelProps>();
const chat = useComputedValue<Chat>(GetterTypes.GET_SELECTED_CHAT);
const chatId = computed(() => chat.value.id);
const isEnlarged = ref(false);

const addReaction = async (emoji: string) => {
  emit("closePanel");
  await dispatch(ActionTypes.SEND_MESSAGE_REACTION, {
    messageId: props.messageId,
    chatId: chatId.value,
    reaction: emoji,
  });
};
</script>

<style lang="scss">
@import "./MessageEmojiReactionsPanel.scss";
</style>
