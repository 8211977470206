import { messageInjectionKey } from "@/utils/chat";

import { inject } from "vue";

export const useGetMessageHandlers = () => {
  const messageHandlers = inject(messageInjectionKey);
  if (!messageHandlers) {
    throw new Error(
      "Message handlers must be provided with provide clause up the tree",
    );
  }
  return messageHandlers;
};
