<template>
  <ChatMessageContent
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  >
    <ChatMessageSender v-bind="props" />
    <DeletedMessage
      v-if="message.isDeleted"
      :is-mine="isMine"
      :message-type="message.type"
    />
    <AudioMessagePlayer v-else :message="message" />
  </ChatMessageContent>
</template>

<script setup lang="ts">
import ChatMessageContent from "@/components/chat/chatContainer/ChatMessageContent/ChatMessageContent.vue";
import ChatMessageSender from "@/components/chat/chatContainer/ChatMessageSender/ChatMessageSender.vue";
import { AudioMessage, Message } from "@/store/chats/types";
import AudioMessagePlayer from "../AudioMessagePlayer/AudioMessagePlayer.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import DeletedMessage from "../DeletedMessage/DeletedMessage.vue";

interface ChatMessageProps {
  message: AudioMessage;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageProps>();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const isMine = computed(() => props.message.senderId === authId.value);
</script>
