import { IListItem, ListItemTypeEnum } from "@/types/list";
import { LocaleTypeEnum } from "@/types/localization";
import { getChangeLanguageHandler } from "./getChangeLanguageHandler";
import store from "@/store";

export const getSettingsChangeLanguageMenuItems = (): IListItem[] => {
  const { isMobileApp } = store.getters.GET_APP_ENVIRONMENT;
  const isHidden = !isMobileApp;

  return [
    {
      text: "settings.changeLanguageContent.czech",
      type: ListItemTypeEnum.Lang,
      isHidden,
      lang: LocaleTypeEnum.CS,
      onClick: getChangeLanguageHandler(LocaleTypeEnum.CS),
    },
    {
      text: "settings.changeLanguageContent.english",
      type: ListItemTypeEnum.Lang,
      isHidden,
      lang: LocaleTypeEnum.EN,
      onClick: getChangeLanguageHandler(LocaleTypeEnum.EN),
    },
    {
      text: "settings.changeLanguageContent.slovak",
      type: ListItemTypeEnum.Lang,
      isHidden,
      lang: LocaleTypeEnum.SK,
      onClick: getChangeLanguageHandler(LocaleTypeEnum.SK),
    },
    {
      text: "settings.changeLanguageContent.ukrainian",
      type: ListItemTypeEnum.Lang,
      isHidden,
      lang: LocaleTypeEnum.UA,
      onClick: getChangeLanguageHandler(LocaleTypeEnum.UA),
    },
  ];
};
