import { getPartnerIdFromChatId } from "@/firebase/utils";
import { ActionTypes } from "@/store";
import { ButtonObject } from "@/types/button";
import { ChatSettingsScreenEnum } from "@/types/chat";
import { computed } from "vue";
import { ChatSettingsDetailsPropsObject } from "../ChatSettingsDetails";
import { useGetChatSettingsCommonProperties } from "../useGetChatSettingsCommonProperties";
import { blockUserWarningPoints } from "./blockUserWarningPoints";
import { getPartnerFromSelectedChat } from "@/utils/users";

export const useGetChatSettingsBlockUserProperties = () => {
  const { authId, selectedChatId, route, router, dispatch } =
    useGetChatSettingsCommonProperties();
  const partner = getPartnerFromSelectedChat();

  const blockUserProperties = computed<ChatSettingsDetailsPropsObject>(() => {
    return {
      type: ChatSettingsScreenEnum.BlockUser,
      primaryButton: primaryButton.value,
      imageSrc: partner?.picture,
      warningPoints: blockUserWarningPoints,
    };
  });
  const primaryButton = computed<ButtonObject>(() => {
    return {
      handleClick: handlePrimaryButtonClick,
    };
  });

  const handlePrimaryButtonClick = async (): Promise<void> => {
    const chatId = selectedChatId.value ?? (route.params.chat_id as string);
    const partnerId = getPartnerIdFromChatId(authId.value, chatId);
    await dispatch(ActionTypes.BLOCK_USER, { partnerId, chatId });
    router.back();
  };

  return { blockUserProperties };
};
