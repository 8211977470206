<template>
  <div class="select-box">
    <input
      ref="input"
      :placeholder="$t('select.UsersSelectBox.placeholder')"
      :value="filter"
      @input="updateFilter"
    />
    <div v-if="users.length > 0" class="options-container">
      <div class="options">
        <div
          v-for="user in users"
          :key="user.id"
          class="option"
          @click="setSelectedUsers(user)"
        >
          <img class="picture" :src="user.picture" alt="person" />
          <span class="username">{{ user.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GetterTypes } from "@/store";
import { debounceSearchUsersByNameCallbackAsync } from "@/services/maitrejaApi/search";

export default {
  emits: ["set-selected-users"],
  data() {
    return {
      users: [],
      filter: "",
    };
  },
  computed: {
    ...mapGetters({
      accessToken: GetterTypes.GET_AUTH_ACCESS_TOKEN,
      authId: GetterTypes.GET_AUTH_ID,
      environment: GetterTypes.GET_APP_ENVIRONMENT,
    }),
  },
  mounted() {
    const input = this.$refs.input;
    input.focus({ preventScroll: true });
  },
  methods: {
    async updateFilter(e) {
      const target = e.target;
      const newFilter = target.value;
      this.filter = newFilter;
      this.users = [];
      if (!newFilter) return;
      debounceSearchUsersByNameCallbackAsync({
        searchUsername: newFilter,
        accessToken: this.accessToken,
        setUsers: this.setUsers,
        isMobileApp: this.environment.isMobileApp,
      });
    },
    setUsers(users) {
      const newUsers = users.filter((user) => user.id !== this.authId);
      this.users = newUsers;
    },
    setSelectedUsers(user) {
      this.$emit("set-selected-users", user);
      this.filter = "";
      this.users = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/select/selectBox.scss";
</style>
