import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "./postWebviewMessage";
import store from "@/store";

export const handleAllowAccessToContacts = (): void => {
  const { isAndroid } = store.getters.GET_APP_ENVIRONMENT;
  // disabled for Android due to problems with app review
  if (isAndroid) {
    return;
  }

  postWebviewMessage({
    action: BridgeActionEnum.GetContacts,
  });
};
