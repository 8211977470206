<template>
  <span
    v-if="message.isMine && selectedMessageIds"
    class="checkbox-wrapper hover"
  >
    <Icon
      :icon="
        selectedMessageIds.includes(message.id)
          ? IconType.CHECKBOX_ON
          : IconType.CHECKBOX_OFF
      "
      class="checkbox"
      @click="toggleSelectMessage(message.id)"
    />
  </span>
</template>

<script setup lang="ts">
import { GetterTypes, MutationTypes, store } from "@/store";
import { IconType } from "@/types/icons";
import Icon from "../icons/Icon/Icon.vue";
import { useComputedValue } from "@/composables";
import { NonAutomaticMessage } from "@/store/chats/types";

interface Props {
  message: NonAutomaticMessage;
}

defineProps<Props>();

const selectedMessageIds = useComputedValue<string[] | null>(
  GetterTypes.GET_SELECTED_MESSAGE_IDS,
);

const toggleSelectMessage = (messageId: string) => {
  store.commit(MutationTypes.TOGGLE_SELECTED_MESSAGE_ID, { messageId });
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/checkbox/selectMessageCheckbox.scss";
</style>
