import router from "@/router";
import { getOnboardingPathsArray } from "./getOnboardingPathsArray";
import store from "@/store";

export const handleOnboardingRedirect = () => {
  const onboardingPathsArray = getOnboardingPathsArray();
  const isWelcomeShown = onboardingPathsArray.length === 3;
  const { isMobileApp } = store.getters.GET_APP_ENVIRONMENT;

  if (isMobileApp && isWelcomeShown) {
    router.push("/register/welcome");
    return;
  }

  router.push("/");
};
