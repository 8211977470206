<template>
  <div v-if="isBannerShown" class="ringingCallsContainer">
    <RingingCallBanner
      v-for="call in ringingCalls"
      :key="call.chatId"
      v-bind="call"
    />
  </div>
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { useComputedValue } from "@/composables";
import { FirestoreRingingCall } from "@/store/auth/types";
import RingingCallBanner from "../RingingCallBanner/RingingCallBanner.vue";
import { AppEnvironment, LayoutTypes } from "@/store/app/state";
import { computed } from "vue";
const ringingCalls = useComputedValue<FirestoreRingingCall[]>(
  GetterTypes.GET_RINGING_CALLS,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const environment = useComputedValue<AppEnvironment>(
  GetterTypes.GET_APP_ENVIRONMENT,
);
const isBannerShown = computed(
  () =>
    ringingCalls.value.length &&
    !environment.value.isMobileApp &&
    layoutType.value === LayoutTypes.DESKTOP,
);
</script>

<style lang="scss" scoped>
@import "./RingingCallsContainer.scss";
</style>
