import { GetterTree } from "vuex";

import { RootState } from "@/store/state";
import { AppState, Popup, PopupType, LayoutType, Layout } from "./state";

export enum AppGetterTypes {
  GET_POPUP = "GET_POPUP",
  GET_POPUP_TYPE = "GET_POPUP_TYPE",
  GET_FULL_SIZE_IMAGE = "GET_FULL_SIZE_IMAGE",
  GET_LAYOUT = "GET_LAYOUT",
  GET_LAYOUT_TYPE = "GET_LAYOUT_TYPE",
  GET_LAYOUT_WIDTH = "GET_LAYOUT_WIDTH",
  GET_LAYOUT_HEIGHT = "GET_LAYOUT_HEIGHT",
  GET_LAYOUT_MESSAGE_INPUT_HEIGHT = "GET_LAYOUT_MESSAGE_INPUT_HEIGHT",
  GET_LAYOUT_BG_COLOR_TOP = "GET_LAYOUT_BG_COLOR_TOP",
  GET_LAYOUT_BG_COLOR_BOTTOM = "GET_LAYOUT_BG_COLOR_BOTTOM",
  GET_NOTIFICATIONS_STATUS = "GET_NOTIFICATIONS_STATUS",
  GET_APP_ENVIRONMENT = "GET_APP_ENVIRONMENT",
  GET_PHONE_CONTACTS_STATUS = "GET_PHONE_CONTACTS_STATUS",
  GET_SNACKBAR = "GET_SNACKBAR",
  GET_SNACKBAR_IS_SHOWN = "GET_SNACKBAR_IS_SHOWN",
  GET_SNACKBAR_IS_MOUNTED = "GET_SNACKBAR_IS_MOUNTED",
  GET_IS_LOADING = "GET_IS_LOADING",
  GET_LANGUAGE = "GET_LANGUAGE",
  GET_ONBOARDING_ROUTES = "GET_ONBOARDING_ROUTES",
  GET_APP_DETAILS = "GET_APP_DETAILS",
  GET_APP_DETAILS_REGION_CODE = "GET_APP_DETAILS_REGION_CODE",
  GET_MICROPHONE_STATUS = "GET_MICROPHONE_STATUS",
}

export interface AppGetters {
  [AppGetterTypes.GET_POPUP](state: AppState): Popup;
  [AppGetterTypes.GET_POPUP_TYPE](state: AppState): PopupType;
  [AppGetterTypes.GET_FULL_SIZE_IMAGE](state: AppState): string | undefined;
  [AppGetterTypes.GET_LAYOUT](state: AppState): Layout;
  [AppGetterTypes.GET_LAYOUT_TYPE](state: AppState): LayoutType;
  [AppGetterTypes.GET_LAYOUT_WIDTH](state: AppState): number;
  [AppGetterTypes.GET_LAYOUT_HEIGHT](state: AppState): number;
  [AppGetterTypes.GET_IS_LOADING](state: AppState): AppState["isLoading"];
  [AppGetterTypes.GET_LAYOUT_BG_COLOR_TOP](
    state: AppState,
  ): AppState["layout"]["backgroundColorType"]["top"];
  [AppGetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM](
    state: AppState,
  ): AppState["layout"]["backgroundColorType"]["bottom"];
  [AppGetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM](
    state: AppState,
  ): AppState["layout"]["backgroundColorType"]["bottom"];
  [AppGetterTypes.GET_LAYOUT_MESSAGE_INPUT_HEIGHT](
    state: AppState,
  ): AppState["layout"]["messageInputHeight"];
  [AppGetterTypes.GET_NOTIFICATIONS_STATUS](
    state: AppState,
  ): AppState["settings"]["notifications"]["status"];
  [AppGetterTypes.GET_PHONE_CONTACTS_STATUS](
    state: AppState,
  ): AppState["settings"]["contacts"]["status"];
  [AppGetterTypes.GET_LANGUAGE](state: AppState): AppState["lang"];
  [AppGetterTypes.GET_ONBOARDING_ROUTES](
    state: AppState,
  ): AppState["onboardingRoutes"];
  [AppGetterTypes.GET_APP_DETAILS](state: AppState): AppState["appDetails"];
  [AppGetterTypes.GET_APP_DETAILS_REGION_CODE](
    state: AppState,
  ): AppState["appDetails"]["regionCode"];
  [AppGetterTypes.GET_APP_ENVIRONMENT](
    state: AppState,
  ): AppState["environment"];
  [AppGetterTypes.GET_MICROPHONE_STATUS](
    state: AppState,
  ): AppState["settings"]["microphone"]["status"];
}

export const appGetters: GetterTree<AppState, RootState> & AppGetters = {
  [AppGetterTypes.GET_POPUP]: (state) => state.popup,
  [AppGetterTypes.GET_POPUP_TYPE]: (state) => state.popup.type,
  [AppGetterTypes.GET_FULL_SIZE_IMAGE]: (state) => state.fullSizeImage.src,
  [AppGetterTypes.GET_LAYOUT]: (state) => state.layout,
  [AppGetterTypes.GET_LAYOUT_TYPE]: (state) => state.layout.type,
  [AppGetterTypes.GET_LAYOUT_WIDTH]: (state) => state.layout.width,
  [AppGetterTypes.GET_LAYOUT_HEIGHT]: (state) => state.layout.height,
  [AppGetterTypes.GET_LAYOUT_BG_COLOR_TOP]: (state) =>
    state.layout.backgroundColorType.top,
  [AppGetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM]: (state) =>
    state.layout.backgroundColorType.bottom,
  [AppGetterTypes.GET_LAYOUT_MESSAGE_INPUT_HEIGHT]: (state) =>
    state.layout.messageInputHeight,
  [AppGetterTypes.GET_NOTIFICATIONS_STATUS]: (state) =>
    state.settings.notifications.status,
  [AppGetterTypes.GET_PHONE_CONTACTS_STATUS]: (state) =>
    state.settings.contacts.status,
  [AppGetterTypes.GET_SNACKBAR]: (state) => state.snackbar,
  [AppGetterTypes.GET_SNACKBAR_IS_SHOWN]: (state) => state.snackbar.isShown,
  [AppGetterTypes.GET_SNACKBAR_IS_MOUNTED]: (state) => state.snackbar.isMounted,
  [AppGetterTypes.GET_IS_LOADING]: (state) => state.isLoading,
  [AppGetterTypes.GET_LANGUAGE]: (state) => state.lang,
  [AppGetterTypes.GET_ONBOARDING_ROUTES]: (state) => state.onboardingRoutes,
  [AppGetterTypes.GET_APP_DETAILS]: (state) => state.appDetails,
  [AppGetterTypes.GET_APP_DETAILS_REGION_CODE]: (state) =>
    state.appDetails.regionCode,
  [AppGetterTypes.GET_APP_ENVIRONMENT]: (state) => state.environment,
  [AppGetterTypes.GET_MICROPHONE_STATUS]: (state) =>
    state.settings.microphone.status,
};
