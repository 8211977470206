<template>
  <div class="emojis-selection">
    <EmojiItem
      v-for="(emoji, i) in EMOJI_CHARACTERS"
      :key="i"
      :emoji="emoji"
      @insert-emoji="insertEmoji"
    />
  </div>
</template>

<script setup lang="ts">
import { EMOJI_CHARACTERS } from "@/data/emoji/emojiCharacters";
import EmojiItem from "../EmojiItem/EmojiItem.vue";

interface Emits {
  (e: "insert-emoji", emoji: string): void;
}

const emit = defineEmits<Emits>();

const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};
</script>

<style lang="scss" scoped>
@import "./EmojisSelection.scss";
</style>
