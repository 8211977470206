<template>
  <div v-click-outside="closeActions" class="desktop-other-actions">
    <div class="desktop-other-actions-content">
      <div
        v-for="item in items"
        :key="item.icon"
        class="desktop-other-actions-item"
        @click="item.action"
      >
        <Icon :icon="item.icon" class="icon" />
        <span class="desktop-other-actions-text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Message } from "@/store/chats/types";
import { IconType } from "@/types/icons";
import { useGetMessageHandlers } from "@/composables";
import { useI18n } from "vue-i18n";
import Icon from "@/components/icons/Icon/Icon.vue";
import { useStore } from "vuex";
import { computed } from "vue";

interface DesktopOtherActions {
  message: Message;
}

interface Emits {
  (e: "closeActions"): void;
}

const emit = defineEmits<Emits>();
const { t } = useI18n();
const { handleModifyMessage, handleRemoveMessage } = useGetMessageHandlers();
const props = defineProps<DesktopOtherActions>();

const closeActions = () => {
  emit("closeActions");
};

const removeMessage = (e: MouseEvent) => {
  e.stopPropagation();
  handleRemoveMessage(props.message);
  closeActions();
};

const modifyMessage = (e: MouseEvent) => {
  e.stopPropagation();
  handleModifyMessage(props.message);
  closeActions();
};

const items = computed(() => [
  {
    icon: IconType.REMOVE_MESSAGE,
    text: t("chat.chatContainer.messages.controls.delete"),
    action: removeMessage,
  },
  {
    icon: IconType.EDIT,
    text: t("chat.chatContainer.messages.controls.modify"),
    action: modifyMessage,
  },
]);
</script>

<style lang="scss">
@import "./DesktopOtherActions.scss";
</style>
