import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { BridgeDisplayModeEnum } from "@/types/webview";
import { handleChangeDisplayMode } from "./handleChangeDisplayMode";

interface SetIosContainerSafeAreaBgArgs {
  topColor: LayoutBackgroundColorTypes;
  bottomColor: LayoutBackgroundColorTypes;
}

export const handleSetIosContainerSafeAreaBg = ({
  topColor,
  bottomColor,
}: SetIosContainerSafeAreaBgArgs) => {
  handleChangeDisplayMode(
    BridgeDisplayModeEnum.Container,
    topColor,
    bottomColor,
  );
};
