import { Profile } from "@/store/auth/state";
import { HttpRequestMethodEnum } from "@/types/api";
import { request } from "../api";
import { config } from "@/config";
import { User } from "@/store/users/state";
interface SearchUsersByNameArgs {
  searchUsername: string;
  accessToken: string;
  setUsers: (users: Profile[]) => void;
  isMobileApp: boolean;
}

export const searchUsersByNameCallbackAsync = async ({
  searchUsername,
  accessToken,
  setUsers,
  isMobileApp,
}: SearchUsersByNameArgs) => {
  if (!searchUsername) {
    setUsers([]);
    return;
  }

  if (!isMobileApp && searchUsername.length <= 2) {
    return;
  }
  const query = await request(
    config.api.maitrejaApi.endpoints.searchUserByName,
    HttpRequestMethodEnum.POST,
    { search: searchUsername },
    accessToken,
  );

  const users: Profile[] = query?.users || [];
  setUsers(users);
};
