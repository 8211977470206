import { EMOJI_REGEX } from "@/data";

/**
 * Checks if the given string contains only emojis.
 *
 * @param {string} text - The string to check.
 * @returns {boolean} True if the string contains only emojis, false otherwise.
 */
export const containsOnlyEmojis = (text: string) => {
  const textWithoutEmojis = text.replace(EMOJI_REGEX, "");
  return !textWithoutEmojis.length;
};
