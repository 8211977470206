<template>
  <ChatTextMessage
    v-if="getIsMessageTextType(message)"
    v-bind="commonProps"
    :message="message"
  />
  <ChatCallMessage
    v-else-if="getIsMessageCallType(message)"
    v-bind="commonProps"
    :message="message"
  />
  <ChatAudioMessage
    v-else-if="getIsMessageAudioType(message)"
    v-bind="commonProps"
    :message="message"
  />
  <ChatFriendRequestMessage v-else v-bind="commonProps" :message="message" />
</template>

<script setup lang="ts">
import ChatTextMessage from "@/components/chat/chatContainer/ChatTextMessage/ChatTextMessage.vue";
import ChatCallMessage from "@/components/chat/chatContainer/ChatCallMessage/ChatCallMessage.vue";
import {
  getIsMessageTextType,
  getIsMessageCallType,
  getIsMessageAudioType,
} from "@/utils/message";
import { Message } from "@/store/chats/types";
import ChatAudioMessage from "../ChatAudioMessage/ChatAudioMessage.vue";
import ChatFriendRequestMessage from "../ChatFriendRequestMessage/ChatFriendRequestMessage.vue";
import { computed } from "vue";

interface ChatMessageProps {
  message: Message;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageProps>();

const commonProps = computed(() => ({
  index: props.index,
  visibleMessages: props.visibleMessages,
}));
</script>
