import { BridgeMessageActionObject } from "@/types/webview";
import store from "@/store";

export const postWebviewMessage = (actionObject: BridgeMessageActionObject) => {
  const { isAndroid, isMobileApp } = store.getters.GET_APP_ENVIRONMENT;
  if (!isMobileApp) {
    return;
  }
  // Android
  if (isAndroid) {
    // @ts-expect-error
    window.ReactObserver.postMessage(JSON.stringify(actionObject));
    return;
  }

  // IOS
  // @ts-expect-error
  window.webkit.messageHandlers.ReactObserver.postMessage(actionObject);
};
