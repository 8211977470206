import {
  Attachment,
  Chat,
  ChatTypes,
  MessageType,
  RawAudioMessage,
  RawChat,
  RawMessagesState,
  RawTextMessage,
} from "@/store/chats/types";
import { IconType } from "./icons";

export enum ChatSettingsScreenEnum {
  ReportUser = "reportUser",
  BlockUser = "blockUser",
  UnblockUser = "unblockUser",
  DeleteChat = "deleteChat",
  LeaveGroupConversation = "leaveGroupConversation",
}

export interface IChatSettingsWarningPoint {
  title: string;
  text: string;
  iconName: IconType;
}

export interface ChatTypeProvideObject {
  type: ChatTypes;
}

export interface ISentMessage {
  chatId: string;
  messageId: string;
  messageText?: string;
  firebaseIdToken: string;
  attachments?: Attachment[];
  messageType: MessageType;
  replyToMessageId?: string;
  modifyingMessageId?: string;
}

export interface IDeleteMessagesObject {
  messageIds: string[];
  firebaseIdToken: string;
}

export interface IUploadVideoFile {
  chatId: string;
  messageId: string;
  attachment: Attachment;
}

export interface IUploadFile extends IUploadVideoFile {
  firebaseIdToken: string;
}

export interface IMarkMessageAsReadObject {
  messageId: string;
  firebaseIdToken: string;
}

export interface IdPrefixedObject {
  id: string | number;
}

export type IdPrefixedResultObject<T> = {
  [key: string]: T;
};

export interface IMessageReferenceObject {
  messages: RawMessagesState;
}
export interface IChatModifiedReferenceObject {
  chatId: string;
  rawChat: RawChat | undefined;
  messages: RawMessagesState;
  chatUnsubscribe?: () => void;
  messagesUnsubscribe?: () => void;
  messageIds: string[];
  isVisible?: boolean;
  convertedChat: Chat;
}

export interface ILatestMessagesReturnValue {
  sentAt: Pick<RawTextMessage, "sentAt">;
  chatId: string;
}

export interface IGetExistingChatsReturnValue {
  rawChat: Omit<RawChat, "messageIds">;
  messageIds: string[];
  messages: RawMessagesState;
  chatId: string;
}

export interface IGetVideoDetailsArgs {
  videoCdnId: string;
  accessToken: string;
}

// CDN video details
export enum IVideoCdnProcessingState {
  PendingUpload = "pendingupload",
  Downloading = "downloading",
  Queued = "queued",
  InProgress = "inprogress",
  Ready = "ready",
  Error = "error",
}

export enum IVideoCdnProcessingStep {
  Encoding = "encoding",
  Manifest = "manifest",
}

export interface IVideoCdnStatus {
  errorReasonCode: string;
  errorReasonText: string;
  pctComplete: string;
  state: IVideoCdnProcessingState;
  step?: IVideoCdnProcessingStep;
}

export interface IVideoCdnDetailResult {
  thumbnail: string;
  playback: {
    hls: string;
  };
  status: IVideoCdnStatus;
  readyToStream: boolean;
}

export interface IVideoCdnDetail {
  result: IVideoCdnDetailResult;
}

export enum VideoDisplayStatus {
  Done = "done",
  Processing = "processing",
  Error = "error",
  Uploading = "uploading",
}

export interface IUpdateAttachmentSourcesArgs {
  chatId: string;
  messageId: string;
  attachmentIdx: number;
  thumbnailUrl: string;
  url: string;
}
export type IUpdateAttachmentSourcesCommonArgs = {
  chatId: string;
  messageId: string;
  attachmentIdx: number;
} & IGetVideoDetailsArgs;

export interface IUpdateAttachmentProgressArgs {
  chatId: string;
  messageId: string;
  attachmentIdx: number;
  progress: number;
}

export interface ISendMessageArgs {
  chatId: string;
  message: RawTextMessage | RawAudioMessage;
  messageType: MessageType;
  modifyingMessageId?: string;
}
export interface ISendMessageReactionArgs {
  chatId: string;
  messageId: string;
  reaction: string;
}
export interface IRemoveMessageReactionArgs {
  chatId: string;
  messageId: string;
}

export interface ReactionsItemUser {
  id: number;
  name: string;
  picture: string;
}

export interface ReactionsItem {
  user: ReactionsItemUser;
  emoji: string;
  isMine: boolean;
}
