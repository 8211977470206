import { RawAudioMessage, RawTextMessage } from "@/store/chats/types";

type RawTextOrAudioMessage = RawTextMessage | RawAudioMessage;

export const combineUpdatedRawTextMessage = <T extends RawTextOrAudioMessage>(
  stateMessage: T,
  incomingParsedMessage: T,
): T => {
  const newAttachments = incomingParsedMessage.attachments;
  const combinedAttachments = stateMessage.attachments?.map((att, idx) => {
    const newAtt = newAttachments?.[idx];
    if (!newAtt) {
      return att;
    }
    return { ...att, ...newAtt };
  });

  const newParsedMessage: T = {
    ...incomingParsedMessage,
    attachments: combinedAttachments,
  };

  return removeUndefinedFields(stateMessage, newParsedMessage);
};

const removeUndefinedFields = <T extends RawTextOrAudioMessage>(
  stateMessage: T,
  incomingParsedMessage: T,
): T => {
  const result: T = {
    ...stateMessage,
  };

  for (const key in stateMessage) {
    const value = incomingParsedMessage[key];

    if (!value) {
      continue;
    }
    delete result[key];
  }

  return { ...result, ...incomingParsedMessage };
};
