import {
  useHandleBackgroundChange,
  useComputedValue,
  useHandleRouteParamsToStore,
} from "@/composables";
import { GetterTypes, MutationTypes } from "@/store";
import { PopupType, LayoutTypes, AppEnvironment } from "@/store/app/state";
import { Chat, ChatBlockingStatusEnum } from "@/store/chats/types";
import {
  getLanguage,
  getNotificationsStatus,
  getContactsStatus,
  getAppDetails,
  handleReceiveContainerMessage,
  postWebviewMessage,
} from "@/utils/api";
import { handleUpdatePhoneContacts } from "@/utils/users";
import { computed, watch, onMounted, nextTick, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { BridgeActionEnum } from "@/types/webview";
import { config } from "@/config";
import { getMicrophoneStatus } from "@/utils/api/getMicrophoneStatus";

export const useLoadAppProperties = () => {
  useHandleBackgroundChange();
  useHandleRouteParamsToStore();

  const route = useRoute();
  const { commit } = useStore();
  const { locale } = useI18n();
  const el = computed(() => document.querySelector("meta[name=viewport]"));

  const popupType = useComputedValue<PopupType>(GetterTypes.GET_POPUP_TYPE);
  const isAppLoading = useComputedValue<boolean>(GetterTypes.GET_IS_LOADING);
  const accToken = useComputedValue<string | undefined>(
    GetterTypes.GET_AUTH_ACCESS_TOKEN,
  );
  const selectedChatId = useComputedValue<string | undefined>(
    GetterTypes.GET_SELECTED_CHAT_ID,
  );
  const blockingStatus = useComputedValue<ChatBlockingStatusEnum | undefined>(
    GetterTypes.GET_SELECTED_CHAT_BLOCKING_STATUS,
  );
  const query = computed(() => route.query?.token);
  const chatIdFromParams = computed(() => route.params.chat_id as string);
  const path = computed<string>(() => route.path);
  const environment = useComputedValue<AppEnvironment>(
    GetterTypes.GET_APP_ENVIRONMENT,
  );

  watch(accToken, () => {
    if (accToken.value) {
      commit(MutationTypes.SET_IS_LOADING, false);
      handleUpdatePhoneContacts();
    }
  });
  watch(path, () => {
    if (path.value === "/") {
      postWebviewMessage({ action: BridgeActionEnum.DisableBack });
      return;
    }
    postWebviewMessage({ action: BridgeActionEnum.EnableBack });
  });

  const updateLanguage = () => {
    const currentLocale = getLanguage();
    locale.value = currentLocale;
    commit(MutationTypes.SET_LANG, currentLocale);
  };

  watch(query, () => {
    if (query.value) {
      updateLanguage();
    }
  });

  const handleInitialization = (): void => {
    updateLanguage();
    getNotificationsStatus();
    getMicrophoneStatus();
    getContactsStatus();
    getAppDetails();
  };

  const receiveContainerMessage = (e: MessageEvent<any>) => {
    handleReceiveContainerMessage(e);
  };

  const handleResize = (e: Event) => {
    const target = e.target as Window;
    setLayout(target.innerWidth);
  };

  const getLayoutType = (width: number) => {
    return width > config.constants.CHAT_MOBILE_WIDTH
      ? LayoutTypes.DESKTOP
      : LayoutTypes.MOBILE;
  };

  const setLayout = (width: number) => {
    commit(MutationTypes.SET_LAYOUT, {
      type: getLayoutType(width),
      width,
    });
  };

  const addMaximumScaleToMetaViewport = () => {
    if (!el.value) {
      return;
    }

    if (!environment.value.isIos) {
      return;
    }

    const metaContent = el.value.getAttribute("content");
    let newContent = [metaContent, "maximum-scale=1.0"].join(",");
    let re = /maximum\-scale=[0-9\.]+/g;
    if (!metaContent) {
      return;
    }

    if (re.test(metaContent)) {
      newContent = metaContent.replace(re, "maximum-scale=1.0");
    }

    el.value.setAttribute("content", newContent);
  };

  onMounted(() => {
    setLayout(window.innerWidth);
    window.addEventListener("message", receiveContainerMessage);
    window.addEventListener("resize", handleResize);

    nextTick(() => {
      addMaximumScaleToMetaViewport();
      if (!selectedChatId.value && chatIdFromParams.value) {
        commit(MutationTypes.SET_SELECTED_CHAT, {
          chatId: chatIdFromParams.value,
        });
      }
    });
  });

  onUnmounted(() => {
    window.removeEventListener("message", receiveContainerMessage);
    window.removeEventListener("resize", handleResize);
  });

  handleInitialization();

  return { isAppLoading, blockingStatus, popupType };
};
