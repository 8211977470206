import { BridgeStatusEnum } from "@/types/webview";

interface OnboardingNotificationTranslationArgs {
  status: BridgeStatusEnum;
  property: string;
}

export const getOnboardingNotificationTranslationString = ({
  property,
  status,
}: OnboardingNotificationTranslationArgs): string => {
  return `settings.notificationsContent.switch.${status}.${property}`;
};
